import React, { useRef, useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { Link as RouterLink, useOutletContext } from 'react-router-dom';
// material
import {
    TextField,
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
    Button, IconButton, TableHead,
    useTheme,
    Dialog,
    DialogTitle,
    DialogContent,
    CircularProgress
} from '@mui/material';
import Scrollbar from '../../components/Scrollbar';
import request from '../../components/request'
import editFill from '@iconify/icons-eva/edit-fill';
import { MoreMenu, Pager } from '../MyComponents'


function Header({ numSelected, submit, loading = false }) {

    const LoginSchema = Yup.object().shape({
        Key: Yup.string()
    });

    const formik = useFormik({
        initialValues: {
            Key: '',
        },
        validationSchema: LoginSchema,
        onSubmit: (values, actions) => {
            //验证
            console.log('values', values);
            values = { ...values, t: Math.random() }
            submit && submit(values).then(result => {

            })
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    p={2}
                    sx={{
                        ...(numSelected > 0 && {
                            color: 'primary.main',
                            bgcolor: 'primary.lighter'
                        })
                    }}
                >
                    {numSelected > 0 ? (
                        <>
                            <Typography component="div" variant="subtitle1">
                                {numSelected} selected
                            </Typography>
                            <IconButton>
                                <Icon icon={'fluent:delete-24-filled'} />
                            </IconButton>
                        </>
                    ) : (
                        <>

                            <Stack direction={'row'}>
                                <TextField
                                    type="text"
                                    label="设备编号"
                                    {...getFieldProps('Key')}
                                />
                                <Stack flex={1}></Stack>
                            </Stack>

                            <Button
                                disabled={loading}
                                startIcon={<Icon icon={loading ? 'eos-icons:bubble-loading' : 'eva:search-outline'} />}
                                onClick={handleSubmit}
                            >
                                搜索
                            </Button>
                        </>
                    )}
                </Stack>
            </Form>
        </FormikProvider>
    );
}

export default function Index() {
    const [setTools] = useOutletContext();
    const theme = useTheme(); console.log('theme', theme)

    const TABLE_HEAD = [
        { label: '序号', id: 'Num' },
        { label: '开启', id: 'Open' },
        { label: '在线', id: 'Online' },
        { label: '设备类型/编号', id: 'Type/SN' },
        { label: '设备名称', id: 'Name' },
        { label: '车位', id: 'IsCheWei' },
        { label: '车位进/出/子库编号', id: 'CheWeiInOrOut' },
        // { label: '子库编号', id: 'SubWareCode' },
        { label: '单元/密码', id: 'IsDanYuan' },
        { label: '栋数/单元', id: 'Dong' },
        // { label: '单元', id: 'DanYuan' },
        { label: '其它', id: 'IsElse' },
        { label: '编号/备注', id: 'ElseNumberAndRemark' },
        { label: '操作', id: '', align: 'right' }
    ];

    useEffect(() => {
        setTools([
            {
                title: '增加',
                icon: 'carbon:add',
                path: '/dashboard/Device/list/add',
            }
        ]);
    }, []);

    return (
        <Card>
            <Pager url='/Web/Office/Device/DeviceList' pageSize={50}>
                {({ pageData, handleChangePage, searchSubmit, loading }) => {
                    return (
                        <>
                            <Header numSelected={0} submit={searchSubmit} loading={loading} />
                            <Scrollbar>
                                <TableContainer sx={{ minWidth: 800 }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {TABLE_HEAD.map((headCell) => (
                                                    <TableCell align={headCell.align ?? 'inherit'}>
                                                        <div dangerouslySetInnerHTML={{ __html: headCell.label }}></div>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {pageData.data.map((row, index) => {
                                                const { id, Open, Online, DeviceType, SN, Name, IsCheWei, SubWareCode, CheWeiInOrOut, IsDanYuan, Dong, DanYuan, DanYuanPassword, IsElse, ElseCode, ElseRemark, RelevanceDeviceSN } = row;

                                                let showMa = (DeviceType === 'C01') && IsCheWei && (CheWeiInOrOut === 'in') && SubWareCode === '';

                                                return (
                                                    <TableRow hover>
                                                        <TableCell align="left">{index + 1}</TableCell>

                                                        <TableCell align="left"><Icon style={{ color: !Open ? theme.palette.grey[400] : theme.palette.primary.main }} icon={!Open ? "ep:close-bold" : "ep:select"} /></TableCell>


                                                        <TableCell align="left">
                                                            {(DeviceType === 'A01' || DeviceType === 'C01') ?
                                                                <Icon style={{ color: !Online ? theme.palette.grey[400] : theme.palette.primary.main }} icon={!Online ? "ep:close-bold" : "ep:select"} />
                                                                :
                                                                <Icon style={{ color: theme.palette.grey[400] }} icon={"fe:disabled"} />
                                                            }
                                                        </TableCell>

                                                        <TableCell align="left">
                                                            {`${DeviceType}`}<br />{`${SN}`}
                                                            {
                                                                RelevanceDeviceSN &&
                                                                <Stack direction={'row'} alignItems={'center'} sx={{ color: theme.palette.grey[400] }}>
                                                                    <Icon style={{ marginTop: 4 }} icon={"mdi:link-box-variant-outline"} />
                                                                    <span>{RelevanceDeviceSN}</span>
                                                                </Stack>
                                                            }
                                                        </TableCell>
                                                        <TableCell align="left">{Name}</TableCell>

                                                        <TableCell align="left"><Icon style={{ color: !IsCheWei ? theme.palette.grey[400] : theme.palette.primary.main }} icon={!IsCheWei ? "ep:close-bold" : "ep:select"} /></TableCell>
                                                        <TableCell align="left">
                                                            <Stack direction={'row'} alignItems={'center'}>
                                                                {showMa && <MaView SN={SN} />}
                                                                <div>
                                                                    {(CheWeiInOrOut ?? "") === "" ? "" : (CheWeiInOrOut === 'in' ? "进口" : "出口")}<br />{SubWareCode}
                                                                </div>
                                                            </Stack>
                                                        </TableCell>

                                                        <TableCell align="left">
                                                            <Icon style={{ color: !IsDanYuan ? theme.palette.grey[400] : theme.palette.primary.main }} icon={!IsDanYuan ? "ep:close-bold" : "ep:select"} />
                                                            <br />
                                                            {DanYuanPassword}
                                                        </TableCell>
                                                        <TableCell align="left">{Dong}{DanYuan}</TableCell>

                                                        <TableCell align="left"><Icon style={{ color: !IsElse ? theme.palette.grey[400] : theme.palette.primary.main }} icon={!IsElse ? "ep:close-bold" : "ep:select"} /></TableCell>
                                                        <TableCell align="left">{ElseCode}<br />{ElseRemark}</TableCell>

                                                        <TableCell align="right">
                                                            <MoreMenu Sub={
                                                                [
                                                                    {
                                                                        label: '编辑',
                                                                        icon: <Icon icon={editFill} width={24} height={24} />,
                                                                        to: `/dashboard/Device/list/edit/${id}`
                                                                    },
                                                                ]
                                                            } />
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Scrollbar>

                            <TablePagination
                                rowsPerPageOptions={-1}
                                component="div"
                                count={pageData.totalCount}
                                rowsPerPage={pageData.pageSize}
                                page={pageData.pageIndex}
                                onPageChange={handleChangePage}
                            />
                        </>
                    )
                }}
            </Pager>
        </Card>
    );
}


const MaView = ({ SN }: any) => {
    const showMaP = async (SN) => {
        setOpen(true);
        const result = await request.PostFormData('Web/Office/Device/WXGetUnlimitedQRCode', { SN });
        if (result.Code === 1) {
            let { FilePath } = result.Result;
            setFilePath(FilePath);
        }
        else {
            global.addMessage(result.Message);
        }
    }
    const [filePath, setFilePath] = useState('loading');
    const [open, setOpen] = useState(false);

    return (
        <>
            <Icon onClick={() => showMaP(SN)} width={30} icon="material-symbols:qr-code" />
            <Dialog onClose={() => setOpen(false)} open={open}>
                <DialogTitle>快速预约小程序码</DialogTitle>
                <DialogContent>
                    {filePath === 'loading' ?
                        <CircularProgress color="inherit" />
                        :
                        <img src={filePath} />
                    }
                </DialogContent>
            </Dialog>
        </>
    );
}