import React, { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Typography,
  Card, CardHeader,
  Avatar, Link,
  Button, FormControl, Select, InputLabel, MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import qs from 'qs'
import { UpLoadInput } from '../MyComponents';
import request from '../../components/request'

const CSelect = (props) => {
  let { labelId, label, source } = props;

  let [data, setData] = useState([]);
  const loadData = async () => {
    var result = await request.GetData(source, {})
    if (result.Code === 1) {
      setData(result.Result);
    }
    else {

    }
  }

  useEffect(() => {
    loadData();
  }, [source])

  return (
    <FormControl sx={{ minWidth: 200 }}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        {...props}
      >
        {data.map((item) => <MenuItem value={item.id}>{item.Name}</MenuItem>)}
      </Select>
    </FormControl>
  )
}

export default function Profile() {

  const loadData = async () => {
    var result = await request.PostFormData('Web/Public/GetLoginProfile')
    if (result.Code === 1) {
      formik.setValues(result.Result);
    }
    else {
      global.addMessage(result.Message);
    }
  }
  useEffect(() => {
    loadData();
  }, [])

  const LoginSchema = Yup.object().shape({
    NickName: Yup.string().required('昵称 必填'),
    //HeadPortrait: Yup.string().required('头像 必填'),
    Name: Yup.string().required('真实姓名/公司名 必填'),
    IDCardNo: Yup.string().required('身份证号码/公司证件号码 必填'),
    ProvinceId: Yup.string().required('省份 必填'),
    CityId: Yup.string().required('城市 必填'),
    AreaId: Yup.string().required('区域 必填'),
    Address: Yup.string().required('地址 必填'),
    Longitude: Yup.number().nullable().required('经度 必填'),
    Latitude: Yup.number().nullable().required('纬度 必填'),
    Introduce: Yup.string().nullable().required('介绍 必填'),
    CustomerTel: Yup.string().nullable().required('客服电话 必填'),
    CCPics: Yup.array(),
  });

  const formik = useFormik({
    initialValues: {
      NickName: '',
      HeadPortrait: '',
      Name: '',
      IDCardNo: '',
      ProvinceId: '',
      CityId: '',
      AreaId: '',
      Address: '',
      Longitude: '',
      Latitude: '',
      Introduce: '',
      CustomerTel: '',
      CCPics: [],
    },
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);
      //验证
      console.log('values', JSON.stringify(values));
      submit(values).then(result => {
        //if (!result) {
        actions.setSubmitting(false);
        //}
      })
    }
  });

  const submit = async (values) => {
    const data = values;
    const result = await request.PostJsonData('Web/Public/UpdateLoginProfile', data);
    if (result.Code === 1) {
      global.addMessage(result.Message);
      return true;
    }
    else
      global.addMessage(result.Message);

    return false;
  }

  const { errors, touched, values, isSubmitting, handleChange, handleSubmit, getFieldProps } = formik;

  const Input = styled('input')({
    display: 'none',
  });

  //console.log('values', values)

  return (
    <Card>
      <CardHeader title="基础信息" subheader="" />
      <Box p={3}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3} alignItems={'flex-start'}>
              <TextField
                autoComplete="NickName"
                type="text"
                label="昵称"
                {...getFieldProps('NickName')}
                error={Boolean(touched.NickName && errors.NickName)}
                helperText={touched.NickName && errors.NickName}
                sx={{ width: 260 }}
              />
              {/* <Stack alignItems={'center'} spacing={1.5}>
                <Avatar alt="Remy Sharp" src={values.HeadPortrait} sx={{ width: 120, height: 120 }} />
                <TextField
                  autoComplete="HeadPortrait"
                  type="text"
                  label="头像"
                  {...getFieldProps('HeadPortrait')}
                  error={Boolean(touched.HeadPortrait && errors.HeadPortrait)}
                  helperText={touched.HeadPortrait && errors.HeadPortrait}
                  sx={{ display: 'none' }}
                />
                <label htmlFor="contained-button-file">
                  <Input accept="image/*" id="contained-button-file" type="file" onChange={async (e) => {
                    if (e.target.files.length > 0) {
                      var result = await request.PostFile(e.target.files[0]);
                      if (result.Code === 1) {
                        formik.setFieldValue('HeadPortrait', result.Result.FilePath);
                      }
                    }
                  }} />
                  <Button variant="contained" component="span">
                    上传头像
                  </Button>
                </label>
                <Button
                  onClick={() => {
                    formik.setFieldValue('HeadPortrait', '');
                  }}
                  color={'grey'} variant="contained" component="span" sx={{ mr: 2 }}>
                  清空头像
                </Button>
              </Stack> */}
              <UpLoadInput title='头像' name='HeadPortrait' formik={formik} />
            </Stack>
          </Form>
        </FormikProvider>
      </Box>
      <CardHeader title="实名信息" subheader="请真实填写以下信息" />
      <Box p={3}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3} alignItems={'flex-start'}>
              <Stack direction={'row'} spacing={2}>
                <TextField
                  autoComplete="Name"
                  type="text"
                  label="真实姓名/公司名"
                  {...getFieldProps('Name')}
                  error={Boolean(touched.Name && errors.Name)}
                  helperText={touched.Name && errors.Name}
                  sx={{ width: 260 }}
                />
                <TextField
                  sx={{ width: 350 }}
                  autoComplete="IDCardNo"
                  type="text"
                  label="身份证号/证件号"
                  {...getFieldProps('IDCardNo')}
                  error={Boolean(touched.IDCardNo && errors.IDCardNo)}
                  helperText={touched.IDCardNo && errors.IDCardNo}
                />
              </Stack>
              <Stack direction={'row'} spacing={2}>
                <CSelect
                  source={"Web/Public/Province"}
                  labelId="ProvinceId"
                  autoComplete="ProvinceId"
                  type="text"
                  label="省份"
                  {...getFieldProps('ProvinceId')}
                  error={Boolean(touched.ProvinceId && errors.ProvinceId)}
                  helperText={touched.ProvinceId && errors.ProvinceId}
                  onChange={(e) => {
                    formik.setFieldValue('CityId', '');
                    formik.setFieldValue('AreaId', '');
                    handleChange(e);
                  }}
                />
                <CSelect
                  source={"Web/Public/City?ProvinceId=" + values.ProvinceId}
                  labelId="CityId"
                  autoComplete="CityId"
                  type="text"
                  label="城市"
                  {...getFieldProps('CityId')}
                  error={Boolean(touched.CityId && errors.CityId)}
                  helperText={touched.CityId && errors.CityId}
                  onChange={(e) => {
                    formik.setFieldValue('CityId', '');
                    handleChange(e);
                  }}
                />
                <CSelect
                  source={"Web/Public/Area?ProvinceId=" + values.ProvinceId + "&CityId=" + values.CityId}
                  labelId="AreaId"
                  autoComplete="AreaId"
                  type="text"
                  label="区域"
                  {...getFieldProps('AreaId')}
                  error={Boolean(touched.AreaId && errors.AreaId)}
                  helperText={touched.AreaId && errors.AreaId}
                />
              </Stack>
              <TextField
                fullWidth
                autoComplete="Address"
                type="text"
                label="详细地址"
                {...getFieldProps('Address')}
                error={Boolean(touched.Address && errors.Address)}
                helperText={touched.Address && errors.Address}
              />
              <Stack direction={'row'} spacing={2} alignItems='center' >
                <TextField
                  autoComplete="CustomerTel"
                  type="text"
                  label="客服电话"
                  {...getFieldProps('CustomerTel')}
                  error={Boolean(touched.CustomerTel && errors.CustomerTel)}
                  helperText={touched.CustomerTel && errors.CustomerTel}
                />
                <TextField
                  autoComplete="Longitude"
                  type="number"
                  label="经度"
                  {...getFieldProps('Longitude')}
                  error={Boolean(touched.Longitude && errors.Longitude)}
                  helperText={touched.Longitude && errors.Longitude}
                />
                <TextField
                  autoComplete="Latitude"
                  type="number"
                  label="纬度"
                  {...getFieldProps('Latitude')}
                  error={Boolean(touched.Latitude && errors.Latitude)}
                  helperText={touched.Latitude && errors.Latitude}
                />
                {/* <Typography>坐标拾取器</Typography> */}
                {/* <Button sx={{ minWidth: 120 }} href="https://lbs.qq.com/getPoint/">坐标拾取器</Button> */}
                <Link target="_blank" rel="noreferrer" href="https://lbs.qq.com/getPoint/" underline="none">
                  坐标拾取器
                </Link>
              </Stack>
              <TextField
                fullWidth
                multiline
                autoComplete="Introduce"
                type="text"
                label="介绍"
                {...getFieldProps('Introduce')}
                error={Boolean(touched.Introduce && errors.Introduce)}
                helperText={touched.Introduce && errors.Introduce}
              />
              <UpLoadInput title='车场图片' name='CCPics' multiple formik={formik} />
            </Stack>

            {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="#">
            Forgot password?
          </Link>
        </Stack> */}
            <Stack sx={{ my: 4 }}></Stack>

            <LoadingButton

              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              更新
            </LoadingButton>
          </Form>
        </FormikProvider>
      </Box>
    </Card >
  );
}
