import React, { useEffect, useState } from 'react';
import {
    Box,
    Stack,
    Checkbox,
    TextField,
    IconButton,
    InputAdornment,
    FormControlLabel,
    Typography,
    Paper,
    Card, CardHeader,
    Avatar,
    Button, FormControl, Select, InputLabel, MenuItem, Switch,
    useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';
import { useFormik, Form, FormikProvider, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import qs from 'qs'
import request from '../../components/request'
import { UpLoadInput, getHelperAndErrorProps, RichText } from '../MyComponents';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import DateRangePicker from '@mui/lab/DateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import cnLocale from 'date-fns/locale/zh-CN';




const CSelect = (props) => {
    let { labelId, label, source } = props;

    let [data, setData] = useState([]);
    const loadData = async () => {
        var result = await request.GetData(source, {})
        if (result.Code === 1) {
            setData(result.Result.data);
        }
        else {

        }
    }

    useEffect(() => {
        loadData();
    }, [source])

    return (
        <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
                {...props}
            >
                {data.map((item) => <MenuItem value={item.id}>{item.Name}</MenuItem>)}
            </Select>
        </FormControl>
    )
}


export default function Add() {
    const theme = useTheme();
    //console.log('theme', theme);
    const navigete = useNavigate();
    const [setTools] = useOutletContext();
    let { id } = useParams();
    let add = id === undefined;

    const loadData = async () => {
        var result = await request.GetData('Web/Office/DoorCard/DoorCardOne?id=' + id, {})
        if (result.Code === 1) {
            formik.setValues(result.Result);
        }
        else {
            global.addMessage(result.Message);
        }
    }
    useEffect(() => {
        if (!add) {
            setTools([
                {
                    title: '下发卡信息到硬件',
                    //icon: 'carbon:delete',
                    onClick: async () => {
                        if (window.confirm('确定要下发卡信息到硬件吗？')) {
                            const result = await request.PostJsonData('Web/Office/DoorCard/SendDoorCardToDevice?id=' + id, {});
                            if (result.Code === 1) {
                                global.addMessage(result.Message);
                                global.myHistory.back();
                                return true;
                            }
                            else
                                global.addMessage(result.Message);
                            return false;
                        }
                    },
                }
            ]);
            loadData();
        }
    }, [])

    const LoginSchema = Yup.object().shape({
        Open: Yup.boolean(),
        CardNumber: Yup.string().required('卡号 必填'),
        HardwareNumber: Yup.string().required('硬件号 必填'),
        //IssueStatus: Yup.boolean(),
        Name: Yup.string().nullable(),
        Mobile: Yup.string().nullable().required('手机 必填'),
        Remark: Yup.string().nullable(),
        Dong: Yup.string().nullable().required('栋数 必填'),
        DanYuan: Yup.string().nullable().required('单元 必填'),
        ElseDevices: Yup.string().nullable(),
    });

    const formik = useFormik({
        initialValues: {
            Open: true,
            CardNumber: '',
            HardwareNumber: '',
            Name: '',
            Mobile: '',
            Remark: '',
            Dong: '',
            DanYuan: '',
            ElseDevices: '',
        },
        validationSchema: LoginSchema,
        onSubmit: (values, actions) => {
            actions.setSubmitting(true);
            //验证
            console.log('values', JSON.stringify(values));
            submit(values).then(result => {
                //if (!result) {
                actions.setSubmitting(false);
                //}
            })
        }
    });

    const submit = async (values) => {
        const data = values;
        const result = add ?
            await request.PostJsonData('Web/Office/DoorCard/CreateDoorCard', data) :
            await request.PostJsonData('Web/Office/DoorCard/UpdateDoorCard?id=' + id, data);
        if (result.Code === 1) {
            global.addMessage(result.Message);
            //navigete('/dashboard/ParkingSpace/list', { replace: true });
            global.myHistory.back();
            return true;
        }
        else
            global.addMessage(result.Message);

        return false;
    }

    const { errors, touched, values, isSubmitting, handleChange, handleSubmit, getFieldProps, setFieldValue } = formik;
    //console.log('formik', formik)
    //console.log('touched', touched)

    return (
        <Card>
            <Box p={3}>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Stack spacing={3} alignItems={'flex-start'}>

                            <FormControlLabel
                                control={
                                    <Switch
                                        name='Open'
                                        color="primary"
                                        checked={values.Open}
                                        onChange={handleChange}
                                    />
                                }
                                label="开启"
                                labelPlacement="end"

                            />

                            <Stack direction="row" spacing={2}>
                                <TextField
                                    autoComplete="CardNumber"
                                    type="text"
                                    label="卡号"
                                    {...getFieldProps('CardNumber')}
                                    {...getHelperAndErrorProps(formik, 'CardNumber')}
                                    sx={{}}
                                />
                                <TextField
                                    autoComplete="HardwareNumber"
                                    type="text"
                                    label="硬件号"
                                    {...getFieldProps('HardwareNumber')}
                                    {...getHelperAndErrorProps(formik, 'HardwareNumber')}
                                    sx={{}}
                                />
                            </Stack>

                            <Stack direction="row" spacing={2}>
                                <TextField
                                    autoComplete="Name"
                                    type="text"
                                    label="所属人"
                                    {...getFieldProps('Name')}
                                    {...getHelperAndErrorProps(formik, 'Name')}
                                    sx={{}}
                                />
                                <TextField
                                    autoComplete="Mobile"
                                    type="text"
                                    label="手机"
                                    {...getFieldProps('Mobile')}
                                    {...getHelperAndErrorProps(formik, 'Mobile')}
                                    sx={{}}
                                />
                                <TextField
                                    autoComplete="Remark"
                                    type="text"
                                    label="备注"
                                    {...getFieldProps('Remark')}
                                    {...getHelperAndErrorProps(formik, 'Remark')}
                                    sx={{}}
                                />
                            </Stack>

                            <Stack direction="row" spacing={2}>
                                <TextField
                                    autoComplete="Dong"
                                    type="text"
                                    label="栋数"
                                    {...getFieldProps('Dong')}
                                    {...getHelperAndErrorProps(formik, 'Dong')}
                                    sx={{}}
                                />

                                <TextField
                                    autoComplete="DanYuan"
                                    type="text"
                                    label="单元"
                                    {...getFieldProps('DanYuan')}
                                    {...getHelperAndErrorProps(formik, 'DanYuan')}
                                    sx={{}}
                                />
                            </Stack>

                            <Stack direction="row" spacing={2}>
                                <TextField
                                    autoComplete="ElseDevices"
                                    type="text"
                                    label="其它设备"
                                    {...getFieldProps('ElseDevices')}
                                    {...getHelperAndErrorProps(formik, 'ElseDevices')}
                                    sx={{ width: 600 }}
                                    helperText={'输入设备SN码，多个设备SN码以空格分隔'}
                                />

                            </Stack>

                            <Stack sx={{ my: 4 }}></Stack>

                            <LoadingButton

                                size="large"
                                type="submit"
                                variant="contained"
                                loading={isSubmitting}
                            >
                                保存
                            </LoadingButton>
                        </Stack>
                    </Form>
                </FormikProvider>
            </Box>
        </Card >
    );
}
