import React from 'react';
import { Outlet, matchPath, useLocation, } from 'react-router-dom';
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Link,
  Box,
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import Page from '../../components/Page';
import { SubMenus, TopRightTools } from '../MyComponents'

export default function OwnerLayout() {

  const [tools, setTools] = useState([]);

  const subMenus = [
    {
      title: '车位信息',
      icon: null,
      path: '/dashboard/parkingspace/list',
    },
    {
      title: '设置定价',
      icon: null,
      path: '/dashboard/parkingspace/setparkingspaceprice',
    },
    {
      title: '开放时间',
      icon: null,
      path: '/dashboard/parkingspace/setparkingspacetime',
    },
    {
      title: '预约设定',
      icon: null,
      path: '/dashboard/parkingspace/setparkingspaceorderparam',
    },
    {
      title: '预约订单',
      icon: null,
      path: '/dashboard/parkingspace/order',
    },
    {
      title: '结算账单',
      icon: null,
      path: '/dashboard/parkingspace/bill',
    }
  ]

  return (
    <Page title="Set | Minimal-UI">
      <Container >
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} sx={{ minHeight: 36 }}>
          <Box display={'flex'}>

            <SubMenus Menus={subMenus} />

          </Box>

          <TopRightTools Tools={tools} />

        </Stack>
        
        <Outlet context={[setTools]} />

      </Container>
    </Page>
  );
}
