import { Icon } from '@iconify/react';
import { useRef, useState, useEffect } from 'react';
import { Link as RouterLink, useOutletContext, useParams } from 'react-router-dom';
// material
import {
    Card,
    CardHeader,
    Table,
    Stack,
    Box,
    Avatar,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer, Button,
    IconButton, TableHead,
    Menu, MenuItem, ListItemIcon, ListItemText, useTheme
} from '@mui/material';
import Scrollbar from '../../components/Scrollbar';
import request from '../../components/request'
import editFill from '@iconify/icons-eva/edit-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';

export default function Index({ SetTools }) {
    const [setTools] = useOutletContext();
    let { id } = useParams();
    const theme = useTheme();
    const [data, setData] = useState({});

    const loadData = async () => {
        var result = await request.GetData('/Web/Office/ParkingSpaceOrder/OrderDetail?id=' + id, {})
        if (result.Code === 1) {
            let _data = result.Result;
            setData(_data);
            let { Status } = _data;
            let tools = [];
            if (Status == '已下单') {
                tools.push({
                    title: '取消订单',
                    icon: 'carbon:add',
                    onClick: async () => {
                        if (window.confirm('确定要取消该订单')) {
                            const result = await request.GetData('Web/Office/ParkingSpaceOrder/CancelOrder?id=' + id, {});
                            if (result.Code === 1) {
                                global.addMessage(result.Message);
                                global.myHistory.back();
                                return true;
                            }
                            else
                                global.addMessage(result.Message);
                            return false;
                        }
                    },
                });
            }
            setTools(tools);
        }
        else
            global.addMessage(result.Message);
    }

    useEffect(() => {
        loadData();
    }, []);

    const cancalCw2 = async (orderNumber, itemId) => {
        if (window.confirm('确定要取消该车位吗? 注:该操作将全额退回该车位费用')) {
            const result = await request.GetData(`Web/Office/ParkingSpaceOrder/CancelCheWei2?orderNumber=${orderNumber}&itemId=${itemId}`, {});
            if (result.Code === 1) {
                global.addMessage(result.Message);
                global.myHistory.back();
                return true;
            }
            else
                global.addMessage(result.Message);
            return false;
        }
    }
    const cancalCw = async (orderNumber, itemId) => {
        if (window.confirm('确定要结束该车位吗？注:只有强制入场(但实际还未入场)的订单可以结束车位')) {
            const result = await request.GetData(`Web/Office/ParkingSpaceOrder/CancelCheWei?orderNumber=${orderNumber}&itemId=${itemId}`, {});
            if (result.Code === 1) {
                global.addMessage(result.Message);
                global.myHistory.back();
                return true;
            }
            else
                global.addMessage(result.Message);
            return false;
        }
    }

    return (
        <Card>
            <Scrollbar>
                <CardHeader title="基础信息" subheader="" />
                <Stack px={4} pt={1} direction={'row'}>
                    <Typography flex={1} variant='body2'>订单号：{data.OrderNumber}</Typography>
                    <Typography flex={1} variant='body2'>下单时间：{data.CreateTime}</Typography>
                    <Typography flex={1} variant='body2'>订单状态：{data.Status}</Typography>
                </Stack>
                {data.FinishTime &&
                    <Stack px={4} pt={1} direction={'row'}>
                        <Typography flex={1} variant='body2'>完成时间：{data.FinishTime}</Typography>
                        <Typography flex={1} variant='body2'></Typography>
                        <Typography flex={1} variant='body2'></Typography>
                    </Stack>
                }
                {data.CloseTime &&
                    <Stack px={4} pt={1} direction={'row'}>
                        <Typography flex={1} variant='body2'>关闭时间：{data.CloseTime}</Typography>
                        <Typography flex={1} variant='body2'></Typography>
                        <Typography flex={1} variant='body2'></Typography>
                    </Stack>
                }
                <CardHeader title="预约时间" subheader="" />
                <Stack px={4} pt={1} direction={'row'}>
                    <Typography flex={1} variant='body2'>预约进入时间：{data.StartTime}</Typography>
                    <Typography flex={1} variant='body2'>预约离开时间：{data.EndTime}</Typography>
                    <Typography flex={1} variant='body2'></Typography>
                </Stack>
                <CardHeader title="车位信息" subheader="" />
                <Stack px={4} pt={1} direction={'row'} >
                    <Table flex={1} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>车位/所属人</TableCell>
                                <TableCell>预定金额</TableCell>
                                <TableCell align="">司机信息</TableCell>
                                <TableCell align="">进场时间</TableCell>
                                <TableCell align="right">出场时间</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.Messages && data.Messages.map(item => {
                                return (<TableRow>
                                    <TableCell>
                                        <div>{item.ParkingSpaceNumber}</div>
                                        <div>{item.OwnerUserName}</div>
                                    </TableCell>
                                    <TableCell>
                                        <div>预付:￥{item.YufuPrice}<span>{item.HasYufu ? '[已付]' : ''}</span></div>
                                        {item.BuchaPrice && <div>补差:￥{item.BuchaPrice}<span>{item.HasBuCha ? '[已付]' : ''}</span></div>}
                                        {item.ShijiPrice && <div>实际:￥{item.ShijiPrice}</div>}
                                    </TableCell>
                                    <TableCell align="">
                                        <div>{item.BindingCarDriverName} {item.BindingCarLicensePlate}</div>
                                        <div>{item.BindingCarDriverMobile}</div>
                                    </TableCell>
                                    <TableCell align="">
                                        {item.ComeInTime}
                                        {item.ArtificialReportingComeIn ? "[强入场]" : ""}
                                        <Stack direction={'row'}>
                                            {item.ComeInTime && <Button size='small' onClick={() => cancalCw2(item.OrderNumber, item.id)} variant="text">取消车位</Button>}
                                            <br />
                                            {/* {item.ArtificialReportingComeIn && <Button onClick={() => cancalCw(item.OrderNumber, item.id)} variant="text">结束车位</Button>} */}
                                            {item.ComeInTime && <Button size='small' onClick={() => cancalCw(item.OrderNumber, item.id)} variant="text">结束车位</Button>}
                                        </Stack>
                                    </TableCell>
                                    <TableCell align="right">
                                        {item.ComeOutTime}
                                    </TableCell>
                                </TableRow>)
                            })}
                        </TableBody>
                    </Table>
                </Stack>
                <CardHeader title="支付信息" subheader="" />
                <Stack px={4} pt={1} direction={'row'}  >
                    <Table flex={1} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>支付类型</TableCell>
                                <TableCell>支付号</TableCell>
                                <TableCell align="">金额</TableCell>
                                <TableCell align="">支付状态</TableCell>
                                <TableCell align="">支付方式</TableCell>
                                <TableCell align="">三方流水号</TableCell>
                                <TableCell align="right">备注</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.PayLogs && data.PayLogs.map(item => {
                                return (<TableRow>
                                    <TableCell>
                                        {item.Type}
                                    </TableCell>
                                    <TableCell>
                                        {item.PayLogNumber}
                                    </TableCell>
                                    <TableCell align="">
                                        ￥{item.Price}
                                    </TableCell>
                                    <TableCell align="">
                                        {item.Pay ? "已支付" : ""}
                                    </TableCell>
                                    <TableCell align="">
                                        {item.PayType}
                                    </TableCell>
                                    <TableCell align="">
                                        {item.ThirdPayNumber}
                                    </TableCell>
                                    <TableCell align="right">
                                        {item.Remark}
                                    </TableCell>
                                </TableRow>)
                            })}
                        </TableBody>
                    </Table>
                </Stack>
                {/* <Stack px={4} pt={1} direction={'row'}  >
                    <Typography flex={1} variant='body2'>订单支付（积分）：{data.Integral_IsPay ? '已支付' : '未支付'}</Typography>
                    <Typography flex={1} variant='body2'>支付时间：{data.Integral_PayTime}</Typography>
                    <Typography flex={1} variant='body2'>支付备注：{data.Integral_PayRemark}</Typography>
                </Stack> */}
                <CardHeader title="会员信息" subheader="" />
                {/* <Stack px={4} pt={1} direction={'row'}  >
                    <Typography flex={1} variant='body2'>省份：{data.AddresseeSheng}</Typography>
                    <Typography flex={1} variant='body2'>城市：{data.AddresseeShi}</Typography>
                    <Typography flex={1} variant='body2'>区县：{data.AddresseeQu}</Typography>
                </Stack> */}
                <Stack px={4} pt={1} pb={4} direction={'row'}  >
                    <Typography flex={1} variant='body2'>预定人：{data.Buyer_NichName}({data.Buyer_Mobile})</Typography>
                </Stack>

                <CardHeader title="订单日志" subheader="" />
                {
                    data.Logs && data.Logs.map(log => {
                        return (
                            <Stack px={4} pt={1} pb={0} direction={'column'}  >
                                <Typography variant='body2'>[{log.CreateTime}] {log.Title}</Typography>
                                <Typography ml={0} variant='caption' sx={{ color: '#757575' }}>{log.Content}</Typography>
                            </Stack>
                        )
                    })
                }
                <Stack pb={4}></Stack>
            </Scrollbar>
        </Card>
    );
}
