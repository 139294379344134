import React, { useRef, useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { Link as RouterLink, useOutletContext } from 'react-router-dom';
// material
import {
    TextField,
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
    Button, IconButton, TableHead,
    useTheme
} from '@mui/material';
import Scrollbar from '../../components/Scrollbar';
import request from '../../components/request'
import editFill from '@iconify/icons-eva/edit-fill';
import { MoreMenu, Pager } from '../MyComponents'


function Header({ numSelected, submit, loading = false }) {

    const LoginSchema = Yup.object().shape({
        Key: Yup.string()
    });

    const formik = useFormik({
        initialValues: {
            Key: '',
        },
        validationSchema: LoginSchema,
        onSubmit: (values, actions) => {
            //验证
            console.log('values', values);
            submit && submit(values).then(result => {

            })
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    p={2}
                    sx={{
                        ...(numSelected > 0 && {
                            color: 'primary.main',
                            bgcolor: 'primary.lighter'
                        })
                    }}
                >
                    {numSelected > 0 ? (
                        <>
                            <Typography component="div" variant="subtitle1">
                                {numSelected} selected
                            </Typography>
                            <IconButton>
                                <Icon icon={'fluent:delete-24-filled'} />
                            </IconButton>
                        </>
                    ) : (
                        <>

                            <Stack direction={'row'}>
                                <TextField
                                    type="text"
                                    label="业主/手机号/房号"
                                    {...getFieldProps('Key')}
                                />
                                <Stack flex={1}></Stack>
                            </Stack>

                            <Button
                                disabled={loading}
                                startIcon={<Icon icon={loading ? 'eos-icons:bubble-loading' : 'eva:search-outline'} />}
                                onClick={handleSubmit}
                            >
                                搜索
                            </Button>
                        </>
                    )}
                </Stack>
            </Form>
        </FormikProvider>
    );
}

export default function Index() {
    const [setTools] = useOutletContext();
    const theme = useTheme(); console.log('theme', theme)

    const TABLE_HEAD = [
        { label: '序号', id: 'Num' },
        { label: '姓名', id: 'Name' },
        { label: '手机', id: 'Mobile' },
        { label: '栋', id: 'JiDong' },
        { label: '单元', id: 'DanYuan' },
        { label: '房号', id: 'FangHao' },
        { label: '其它设备', id: 'Else' },
        { label: '认证', id: 'Authorization' },
        { label: '操作', id: '', align: 'right' }
    ];

    useEffect(() => {
        setTools([
            {
                title: '导入业主数据',
                icon: 'carbon:add',
                path: '/dashboard/owner/list/import',
            }
        ]);
    }, []);

    return (
        <Card>
            <Pager url='/Web/Office/Owner/List' pageSize={50}>
                {({ pageData, handleChangePage, searchSubmit, loading }) => {
                    return (
                        <>
                            <Header numSelected={0} submit={searchSubmit} loading={loading} />
                            <Scrollbar>
                                <TableContainer sx={{ minWidth: 800 }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {TABLE_HEAD.map((headCell) => (
                                                    <TableCell align={headCell.align ?? 'inherit'}>
                                                        <div dangerouslySetInnerHTML={{ __html: headCell.label }}></div>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {pageData.data.map((row, index) => {
                                                const { id, Name, Mobile, JiDong, DanYuan, FangHao, ElseCode } = row.o;
                                                let Authorization = row.ob.id > 0;
                                                const { Mobile: WXMobile, HeadPortrait, NickName } = row.ub;
                                                return (
                                                    <TableRow hover>
                                                        <TableCell align="left">{index + 1}</TableCell>
                                                        <TableCell align="left">{Name}</TableCell>
                                                        <TableCell align="left">{Mobile}</TableCell>
                                                        <TableCell align="left">{JiDong}</TableCell>
                                                        <TableCell align="left">{DanYuan}</TableCell>
                                                        <TableCell align="left">{FangHao}</TableCell>
                                                        <TableCell align="left">{ElseCode}</TableCell>
                                                        <TableCell align="left">
                                                            <Stack className='Authorization'
                                                                sx={{
                                                                    position: 'relative',
                                                                    "& .view1": { display: 'none' },
                                                                    "&:hover .view1": { display: 'block' }
                                                                }}
                                                            >
                                                                <Icon style={{ color: !Authorization ? theme.palette.grey[400] : theme.palette.primary.main }} icon={!Authorization ? "ep:close-bold" : "ep:select"} />
                                                                {Authorization &&
                                                                    <Stack className='view1' spacing={1}
                                                                        sx={{
                                                                            borderRadius: 1,
                                                                            position: 'absolute',
                                                                            width: 200,
                                                                            backgroundColor: '#FFF',
                                                                            zIndex: 999,
                                                                            p: 2,
                                                                            boxShadow: '0 0 2px 0 rgb(145 158 171 / 24%), 0 20px 40px -4px rgb(145 158 171 / 24%)',
                                                                            border: 'solid 1px rgba(145, 158, 171, 0.08)'
                                                                        }}>
                                                                        {HeadPortrait &&<div><img width={40} src={HeadPortrait} /></div>}
                                                                        <div>{NickName}({WXMobile})</div>
                                                                    </Stack>
                                                                }
                                                            </Stack>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <MoreMenu Sub={
                                                                [
                                                                    {
                                                                        label: '编辑',
                                                                        icon: <Icon icon={editFill} width={24} height={24} />,
                                                                        to: `/dashboard/owner/list/edit/${id}`
                                                                    },
                                                                ]
                                                            } />
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Scrollbar>

                            <TablePagination
                                rowsPerPageOptions={-1}
                                component="div"
                                count={pageData.totalCount}
                                rowsPerPage={pageData.pageSize}
                                page={pageData.pageIndex}
                                onPageChange={handleChangePage}
                            />
                        </>
                    )
                }}
            </Pager>
        </Card>
    );
}
