import React, { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Typography,
  Card, CardHeader,
  Avatar,
  Button, FormControl, Select, InputLabel, MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import qs from 'qs'
import request from '../../components/request'
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';

const CSelect = (props) => {
  let { labelId, label, source } = props;

  let [data, setData] = useState([]);
  const loadData = async () => {
    var result = await request.GetData(source, {})
    if (result.Code === 1) {
      setData(result.Result);
    }
    else {

    }
  }

  useEffect(() => {
    loadData();
  }, [source])

  return (
    <FormControl sx={{ minWidth: 200 }}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        {...props}
      >
        {data.map((item) => <MenuItem value={item.id}>{item.name}</MenuItem>)}
      </Select>
    </FormControl>
  )
}

export default function SetParkingSpacePrice() {
  const navigete = useNavigate();
  const [setTools] = useOutletContext();

  const loadData = async () => {
    var result = await request.GetData('Web/Office/ParkingSpace/GetParkingSpacePrice', {})
    if (result.Code === 1) {
      formik.setValues(result.Result);
    }
    else {
      global.addMessage(result.Message);
    }
  }
  useEffect(() => {
    setTools([

    ]);
    loadData();
  }, [])

  const LoginSchema = Yup.object().shape({
    wy_FirstFewHours: Yup.number().required('前面几小时 必填'),
    wy_FirstFewHoursPrice: Yup.number().required('前面几小时价格 必填'),
    wy_EveryFewHoursLater: Yup.number().required('后面每几小时 必填'),
    wy_EveryFewHoursLaterPrice: Yup.number().required('后面每几小时价格 必填'),
    wy_EveryFewHoursOvertime: Yup.number().required('每超时几个小时 必填'),
    wy_EveryFewHoursOvertimePrice: Yup.number().required('每超时几个小时价格 必填'),
    wy_yzZeKou: Yup.number().required('业主折扣 必填'),
    wy_CautionMinMoney: Yup.number().required('最低保证金 必填'),

    yz_FirstFewHours: Yup.number().required('前面几小时 必填'),
    yz_FirstFewHoursPrice: Yup.number().required('前面几小时价格 必填'),
    yz_EveryFewHoursLater: Yup.number().required('后面每几小时 必填'),
    yz_EveryFewHoursLaterPrice: Yup.number().required('后面每几小时价格 必填'),
    yz_EveryFewHoursOvertime: Yup.number().required('每超时几个小时 必填'),
    yz_EveryFewHoursOvertimePrice: Yup.number().required('每超时几个小时价格 必填'),
    yz_yzZeKou: Yup.number().required('业主折扣 必填'),
    yz_CautionMinMoney: Yup.number().required('最低保证金 必填'),
  });

  const formik = useFormik({
    initialValues: {
      wy_FirstFewHours: '',
      wy_FirstFewHoursPrice: '',
      wy_EveryFewHoursLater: '',
      wy_EveryFewHoursLaterPrice: '',
      wy_EveryFewHoursOvertime: '',
      wy_EveryFewHoursOvertimePrice: '',
      wy_yzZeKou: '',
      wy_CautionMinMoney: '',

      yz_FirstFewHours: '',
      yz_FirstFewHoursPrice: '',
      yz_EveryFewHoursLater: '',
      yz_EveryFewHoursLaterPrice: '',
      yz_EveryFewHoursOvertime: '',
      yz_EveryFewHoursOvertimePrice: '',
      yz_yzZeKou: '',
      yz_CautionMinMoney: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);
      //验证
      console.log('values', JSON.stringify(values));
      submit(values).then(result => {
        //if (!result) {
        actions.setSubmitting(false);
        //}
      })
    }
  });

  const submit = async (values) => {
    const data = values;
    const result = await request.PostJsonData('Web/Office/ParkingSpace/UpdateParkingSpacePrice', data);
    if (result.Code === 1) {
      global.addMessage(result.Message);
      window.location.href = window.location.href;
      return true;
    }
    else
      global.addMessage(result.Message);

    return false;
  }

  const { errors, touched, values, isSubmitting, handleChange, handleSubmit, getFieldProps } = formik;

  const Input = styled('input')({
    display: 'none',
  });

  //console.log('values', values)

  return (
    <Card>
      <CardHeader title="公共车位" subheader="" />
      <Box p={3}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3} alignItems={'flex-start'}>

              <Stack spacing={2} direction={'row'}>
                <TextField
                  autoComplete="yz_FirstFewHours"
                  type="number"
                  label="前面几小时"
                  {...getFieldProps('wy_FirstFewHours')}
                  error={Boolean(touched.wy_FirstFewHours && errors.wy_FirstFewHours)}
                  helperText={touched.wy_FirstFewHours && errors.wy_FirstFewHours}
                />
                <TextField
                  autoComplete="wy_FirstFewHoursPrice"
                  type="number"
                  label="前面几小时价格"
                  {...getFieldProps('wy_FirstFewHoursPrice')}
                  error={Boolean(touched.wy_FirstFewHoursPrice && errors.wy_FirstFewHoursPrice)}
                  helperText={touched.wy_FirstFewHoursPrice && errors.wy_FirstFewHoursPrice}
                />
              </Stack>
              <Stack spacing={2} direction={'row'}>
                <TextField
                  autoComplete="wy_EveryFewHoursLater"
                  type="number"
                  label="后面每几小时"
                  {...getFieldProps('wy_EveryFewHoursLater')}
                  error={Boolean(touched.wy_EveryFewHoursLater && errors.wy_EveryFewHoursLater)}
                  helperText={touched.wy_EveryFewHoursLater && errors.wy_EveryFewHoursLater}
                />
                <TextField
                  autoComplete="wy_EveryFewHoursLaterPrice"
                  type="number"
                  label="后面每几小时价格"
                  {...getFieldProps('wy_EveryFewHoursLaterPrice')}
                  error={Boolean(touched.wy_EveryFewHoursLaterPrice && errors.wy_EveryFewHoursLaterPrice)}
                  helperText={touched.wy_EveryFewHoursLaterPrice && errors.wy_EveryFewHoursLaterPrice}
                />
              </Stack>
              <Stack spacing={2} direction={'row'}>
                <TextField
                  autoComplete="wy_EveryFewHoursOvertime"
                  type="number"
                  label="每超时几个小时"
                  {...getFieldProps('wy_EveryFewHoursOvertime')}
                  error={Boolean(touched.wy_EveryFewHoursOvertime && errors.wy_EveryFewHoursOvertime)}
                  helperText={touched.wy_EveryFewHoursOvertime && errors.wy_EveryFewHoursOvertime}
                />
                <TextField
                  autoComplete="wy_EveryFewHoursOvertimePrice"
                  type="number"
                  label="每超时几个小时价格"
                  {...getFieldProps('wy_EveryFewHoursOvertimePrice')}
                  error={Boolean(touched.wy_EveryFewHoursOvertimePrice && errors.wy_EveryFewHoursOvertimePrice)}
                  helperText={touched.wy_EveryFewHoursOvertimePrice && errors.wy_EveryFewHoursOvertimePrice}
                />
              </Stack>
              <Stack spacing={2} direction={'row'}>
                <TextField
                  autoComplete="wy_yzZeKou"
                  type="number"
                  label="业主折扣"
                  {...getFieldProps('wy_yzZeKou')}
                  error={Boolean(touched.wy_yzZeKou && errors.wy_yzZeKou)}
                  helperText={touched.wy_yzZeKou && errors.wy_yzZeKou}
                />
              </Stack>
              <TextField
                autoComplete="wy_CautionMinMoney"
                type="number"
                label="最低保证金"
                {...getFieldProps('wy_CautionMinMoney')}
                error={Boolean(touched.wy_CautionMinMoney && errors.wy_CautionMinMoney)}
                helperText={touched.wy_CautionMinMoney && errors.wy_CautionMinMoney}
              />

            </Stack>
          </Form>
        </FormikProvider>
      </Box>
      <CardHeader title="业主车位" subheader="" />
      <Box p={3}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3} alignItems={'flex-start'}>
              <Stack spacing={2} direction={'row'}>
                <TextField
                  autoComplete="yz_FirstFewHours"
                  type="number"
                  label="前面几小时"
                  {...getFieldProps('yz_FirstFewHours')}
                  error={Boolean(touched.yz_FirstFewHours && errors.yz_FirstFewHours)}
                  helperText={touched.yz_FirstFewHours && errors.yz_FirstFewHours}
                />
                <TextField
                  autoComplete="yz_FirstFewHoursPrice"
                  type="number"
                  label="前面几小时价格"
                  {...getFieldProps('yz_FirstFewHoursPrice')}
                  error={Boolean(touched.yz_FirstFewHoursPrice && errors.yz_FirstFewHoursPrice)}
                  helperText={touched.yz_FirstFewHoursPrice && errors.yz_FirstFewHoursPrice}
                />
              </Stack>
              <Stack spacing={2} direction={'row'}>
                <TextField
                  autoComplete="yz_EveryFewHoursLater"
                  type="number"
                  label="后面每几小时"
                  {...getFieldProps('yz_EveryFewHoursLater')}
                  error={Boolean(touched.yz_EveryFewHoursLater && errors.yz_EveryFewHoursLater)}
                  helperText={touched.yz_EveryFewHoursLater && errors.yz_EveryFewHoursLater}
                />
                <TextField
                  autoComplete="yz_EveryFewHoursLaterPrice"
                  type="number"
                  label="后面每几小时价格"
                  {...getFieldProps('yz_EveryFewHoursLaterPrice')}
                  error={Boolean(touched.yz_EveryFewHoursLaterPrice && errors.yz_EveryFewHoursLaterPrice)}
                  helperText={touched.yz_EveryFewHoursLaterPrice && errors.yz_EveryFewHoursLaterPrice}
                />
              </Stack>
              <Stack spacing={2} direction={'row'}>
                <TextField
                  autoComplete="yz_EveryFewHoursOvertime"
                  type="number"
                  label="每超时几个小时"
                  {...getFieldProps('yz_EveryFewHoursOvertime')}
                  error={Boolean(touched.yz_EveryFewHoursOvertime && errors.yz_EveryFewHoursOvertime)}
                  helperText={touched.yz_EveryFewHoursOvertime && errors.yz_EveryFewHoursOvertime}
                />
                <TextField
                  autoComplete="yz_EveryFewHoursOvertimePrice"
                  type="number"
                  label="每超时几个小时价格"
                  {...getFieldProps('yz_EveryFewHoursOvertimePrice')}
                  error={Boolean(touched.yz_EveryFewHoursOvertimePrice && errors.yz_EveryFewHoursOvertimePrice)}
                  helperText={touched.yz_EveryFewHoursOvertimePrice && errors.yz_EveryFewHoursOvertimePrice}
                />
              </Stack>
              <Stack spacing={2} direction={'row'}>
                <TextField
                  autoComplete="yz_yzZeKou"
                  type="number"
                  label="业主折扣"
                  {...getFieldProps('yz_yzZeKou')}
                  error={Boolean(touched.yz_yzZeKou && errors.yz_yzZeKou)}
                  helperText={touched.yz_yzZeKou && errors.yz_yzZeKou}
                />
              </Stack>
              <TextField
                autoComplete="yz_CautionMinMoney"
                type="number"
                label="最低保证金"
                {...getFieldProps('yz_CautionMinMoney')}
                error={Boolean(touched.yz_CautionMinMoney && errors.yz_CautionMinMoney)}
                helperText={touched.yz_CautionMinMoney && errors.yz_CautionMinMoney}
              />


              <Stack sx={{ my: 4 }}></Stack>

              <LoadingButton

                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                保存
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Box>
    </Card >
  );
}
