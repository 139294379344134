import React from 'react';
import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import settingsFill from '@iconify/icons-eva/settings-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

//图标：https://icon-sets.iconify.design/
//     https://icon-sets.iconify.design/eva/

const sidebarConfig = [
  {
    title: '控制台',
    path: '/dashboard/app',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: '业主',
    //path: '/dashboard/owner',
    icon: getIcon(peopleFill),
    children: [
      {
        title: '业主信息',
        path: '/dashboard/owner/list'
      }, {
        title: '积分管理',
        path: '/dashboard/owner/integral'
      }
    ]
  },
  {
    title: '设备',
    //path: '/dashboard/owner',
    icon: getIcon(settingsFill),
    children: [
      {
        title: '设备信息',
        path: '/dashboard/device/list'
      }, {
        title: '设备日志',
        path: '/dashboard/device/log'
      }, {
        title: '卡片管理',
        path: '/dashboard/device/doorcard/list'
      }
    ]
  },
  {
    title: '车位',
    //path: '/dashboard/parkingSpace',
    icon: getIcon('fa-solid:car'),
    children: [
      {
        title: '车位信息',
        path: '/dashboard/parkingSpace/list'
      }, {
        title: '设置定价',
        path: '/dashboard/parkingSpace/setparkingspaceprice'
      }, {
        title: '开放时间',
        path: '/dashboard/parkingSpace/setparkingspacetime'
      }, {
        title: '预约设定',
        path: '/dashboard/parkingSpace/setparkingspaceorderparam'
      }, {
        title: '预约订单',
        path: '/dashboard/parkingSpace/order'
      }, {
        title: '结算账单',
        path: '/dashboard/parkingSpace/bill'
      }
    ]
  },
  {
    title: '单车',
    //path: '/dashboard/bicycle',
    icon: getIcon('fluent:vehicle-bicycle-16-filled'),
    children: [
      {
        title: '登记信息',
        path: '/dashboard/bicycle/list'
      },
      {
        title: '进出港记录',
        path: '/dashboard/bicycle/inoutlog'
      }
    ]
  },
  {
    title: '访客 | 物品',
    //path: '/dashboard/visitor',
    icon: getIcon('whh:visitor'),
    children: [
      {
        title: '预约记录',
        path: '/dashboard/visitororder/list'
      },
      {
        title: '进出港记录',
        path: '/dashboard/visitororder/inoutlog'
      }
    ]
  },
  {
    title: '商城',
    //path: '/dashboard/shop',
    icon: getIcon('el:shopping-cart'),
    children: [
      {
        title: '产品&服务',
        path: '/dashboard/product/list'
      }, {
        title: '订购订单',
        path: '/dashboard/product/order'
      }, {
        title: '结算账单',
        path: '/dashboard/product/bill'
      }
    ]
  },
  {
    title: '收银台',
    //path: '/dashboard/collect',
    icon: getIcon('ant-design:money-collect-filled'),
    children: [
      {
        title: '账单',
        path: '/dashboard/collect/bill'
      },
      {
        title: '收款码',
        path: '/dashboard/collect/qrCode'
      }
    ]
  },
  {
    title: '动态&反馈',
    //path: '/dashboard/dynamic',
    icon: getIcon('ant-design:message-filled'),
    children: [
      {
        title: '动态信息',
        path: '/dashboard/dynamicmessage/dynamicmessage'
      }, {
        title: '业主反馈',
        path: '/dashboard/dynamicmessage/feedbackworkorder'
      }, {
        title: '公示栏',
        path: '/dashboard/dynamicmessage/publicity'
      }, /* {
        title: '广告',
        path: '/dashboard/dynamic/advertising'
      } */
    ]
  },
  // {
  //   title: '数据&报表',
  //   //path: '/dashboard/settlement',
  //   icon: getIcon('bx:bxs-data'),
  //   children: [
  //     {
  //       title: '车位数据保报表',
  //       path: '/dashboard/settlement/parkingSpace'
  //     }, {
  //       title: '商城数据保报表',
  //       path: '/dashboard/settlement/shop'
  //     }
  //   ]
  // }
];

export const test_sidebarConfig = [
  {
    title: 'user',
    //path: '/dashboard/user',
    icon: getIcon(peopleFill),
    children: [
      {
        title: 'a1',
        path: '/dashboard/user/a1'
      }, {
        title: 'a2',
        path: '/dashboard/user/a2'
      }
    ]
  },
  {
    title: 'product',
    path: '/dashboard/products',
    icon: getIcon(shoppingBagFill)
  },
  {
    title: 'blog',
    path: '/dashboard/blog',
    icon: getIcon(fileTextFill)
  },
  {
    title: 'login',
    path: '/login',
    icon: getIcon(lockFill)
  },
  {
    title: 'register',
    path: '/register',
    icon: getIcon(personAddFill)
  },
  {
    title: 'Not found',
    path: '/404',
    icon: getIcon(alertTriangleFill)
  }
]

export default sidebarConfig;
